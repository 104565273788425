.title-padder {
  padding: $title-padding;
}

h1.title {
  @extend .subtle-txt-shadow;
  padding: 0.5em 0;
}


.subtitle {
  font-weight: normal;
  margin-top: 5px;
  text-shadow: 1px 1px 2px var(--text-shadow);
}

h1.contact-header {
  img {
    max-width: 100px;
    padding: 0;
    margin-left: 0;
    margin-right: 1em;
  }
  span {
    word-wrap: break-word;
  }
  display: flex;
  align-items: center;
}

div.contact-body {
  p {
    margin-bottom: .25em;
  }
}

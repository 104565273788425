/*!
 * KaTeX v0.16.11
 * Plus added customizations for the theme
 */

@import "katex/katex";

.katex-display {
  @media screen and (max-width: $sm-break) {
    overflow-x: auto;
  }
  & {
    padding-bottom: 3px;
    padding-top: 1px;
    width: auto;
    height: auto;
  }
}

.math-display {
  display: inline-block;
  width: 100%;
}



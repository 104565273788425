$katex-font-path: "../../assets/fonts/katex" !default;
$font-family_1: KaTeX_Main;
$font-family_2: KaTeX_SansSerif;
$font-family_3: KaTeX_Typewriter;
$font-family_4: KaTeX_Math;
$font-family_5: KaTeX_AMS;
$font-family_6: KaTeX_Caligraphic;
$font-family_7: KaTeX_Fraktur;
$font-family_8: KaTeX_Script;
$font-family_9: KaTeX_Size1;
$font-family_10: KaTeX_Size2;
$font-family_11: KaTeX_Size3;
$font-family_12: KaTeX_Size4;
$border-color_1: currentColor;

@font-face {
  font-family: KaTeX_AMS;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_AMS-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_AMS-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_AMS-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Caligraphic-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Caligraphic-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Caligraphic-Bold.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Caligraphic;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Caligraphic-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Caligraphic-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Caligraphic-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Fraktur-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Fraktur-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Fraktur-Bold.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Fraktur;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Fraktur-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Fraktur-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Fraktur-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Main-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-Bold.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Main-BoldItalic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-BoldItalic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: italic;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Main-Italic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-Italic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-Italic.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Main;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Main-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Main-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Main-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_Math-BoldItalic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Math-BoldItalic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Math-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Math;
  font-style: italic;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Math-Italic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Math-Italic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Math-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: normal;
  font-weight: 700;
  src: url("#{$katex-font-path}/KaTeX_SansSerif-Bold.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_SansSerif-Bold.woff") format("woff"),url("#{$katex-font-path}/KaTeX_SansSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: italic;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_SansSerif-Italic.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_SansSerif-Italic.woff") format("woff"),url("#{$katex-font-path}/KaTeX_SansSerif-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "KaTeX_SansSerif";
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_SansSerif-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_SansSerif-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_SansSerif-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Script;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Script-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Script-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Script-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size1;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size1-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size1-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size1-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size2;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size2-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size2-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size2-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size3;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size3-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size3-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size3-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Size4;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Size4-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Size4-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Size4-Regular.ttf") format("truetype");
}
@font-face {
  font-family: KaTeX_Typewriter;
  font-style: normal;
  font-weight: 400;
  src: url("#{$katex-font-path}/KaTeX_Typewriter-Regular.woff2") format("woff2"),url("#{$katex-font-path}/KaTeX_Typewriter-Regular.woff") format("woff"),url("#{$katex-font-path}/KaTeX_Typewriter-Regular.ttf") format("truetype");
}
.katex {
  font: normal 1.21em KaTeX_Main, Times New Roman, serif;
  line-height: 1.2;
  text-indent: 0;
  text-rendering: auto;
  * {
    -ms-high-contrast-adjust: none !important;
    border-color: $border-color_1;
  }
  .katex-version {
    &::after {
      content: "0.16.11";
    }
  }
  .katex-mathml {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
  .katex-html {
    >.newline {
      display: block;
    }
  }
  .base {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
  }
  .strut {
    display: inline-block;
  }
  .textbf {
    font-weight: bold;
  }
  .textit {
    font-style: italic;
  }
  .textrm {
    font-family: $font-family_1;
  }
  .textsf {
    font-family: $font-family_2;
  }
  .texttt {
    font-family: $font-family_3;
  }
  .mathnormal {
    font-family: $font-family_4;
    font-style: italic;
  }
  .mathit {
    font-family: $font-family_1;
    font-style: italic;
  }
  .mathrm {
    font-style: normal;
  }
  .mathbf {
    font-family: $font-family_1;
    font-weight: bold;
  }
  .boldsymbol {
    font-family: $font-family_4;
    font-weight: bold;
    font-style: italic;
  }
  .amsrm {
    font-family: $font-family_5;
  }
  .mathbb {
    font-family: $font-family_5;
  }
  .textbb {
    font-family: $font-family_5;
  }
  .mathcal {
    font-family: $font-family_6;
  }
  .mathfrak {
    font-family: $font-family_7;
  }
  .textfrak {
    font-family: $font-family_7;
  }
  .mathboldfrak {
    font-family: $font-family_7;
    font-weight: bold;
  }
  .textboldfrak {
    font-family: $font-family_7;
    font-weight: bold;
  }
  .mathtt {
    font-family: $font-family_3;
  }
  .mathscr {
    font-family: $font-family_8;
  }
  .textscr {
    font-family: $font-family_8;
  }
  .mathsf {
    font-family: $font-family_2;
  }
  .mathboldsf {
    font-family: $font-family_2;
    font-weight: bold;
  }
  .textboldsf {
    font-family: $font-family_2;
    font-weight: bold;
  }
  .mathitsf {
    font-family: $font-family_2;
    font-style: italic;
  }
  .textitsf {
    font-family: $font-family_2;
    font-style: italic;
  }
  .mainrm {
    font-family: $font-family_1;
    font-style: normal;
  }
  .vlist-t {
    display: inline-table;
    table-layout: fixed;
    border-collapse: collapse;
  }
  .vlist-r {
    display: table-row;
  }
  .vlist {
    display: table-cell;
    vertical-align: bottom;
    position: relative;
    >span {
      display: block;
      height: 0;
      position: relative;
      >span {
        display: inline-block;
      }
      >.pstrut {
        overflow: hidden;
        width: 0;
      }
    }
  }
  .vlist-t2 {
    margin-right: -2px;
  }
  .vlist-s {
    display: table-cell;
    vertical-align: bottom;
    font-size: 1px;
    width: 2px;
    min-width: 2px;
  }
  .vbox {
    display: inline-flex;
    flex-direction: column;
    align-items: baseline;
  }
  .hbox {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
  }
  .thinbox {
    display: inline-flex;
    flex-direction: row;
    width: 0;
    max-width: 0;
  }
  .msupsub {
    text-align: left;
  }
  .mfrac {
    >span {
      >span {
        text-align: center;
      }
    }
    .frac-line {
      display: inline-block;
      width: 100%;
      border-bottom-style: solid;
      min-height: 1px;
    }
  }
  .overline {
    .overline-line {
      min-height: 1px;
      display: inline-block;
      width: 100%;
      border-bottom-style: solid;
    }
  }
  .underline {
    .underline-line {
      min-height: 1px;
      display: inline-block;
      width: 100%;
      border-bottom-style: solid;
    }
  }
  .hline {
    min-height: 1px;
    display: inline-block;
    width: 100%;
    border-bottom-style: solid;
  }
  .hdashline {
    min-height: 1px;
    display: inline-block;
    width: 100%;
    border-bottom-style: dashed;
  }
  .rule {
    min-height: 1px;
    display: inline-block;
    border: solid 0;
    position: relative;
  }
  .mspace {
    display: inline-block;
  }
  .llap {
    width: 0;
    position: relative;
    >.inner {
      position: absolute;
      right: 0;
    }
    >.fix {
      display: inline-block;
    }
  }
  .rlap {
    width: 0;
    position: relative;
    >.inner {
      position: absolute;
      left: 0;
    }
    >.fix {
      display: inline-block;
    }
  }
  .clap {
    width: 0;
    position: relative;
    >.inner {
      position: absolute;
      left: 0;
      >span {
        margin-left: -50%;
        margin-right: 50%;
      }
    }
    >.fix {
      display: inline-block;
    }
  }
  .sqrt {
    >.root {
      margin-left: 0.2777777778em;
      margin-right: -0.5555555556em;
    }
  }
  .sizing.reset-size1.size1 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size1.size1 {
    font-size: 1em;
  }
  .sizing.reset-size1.size2 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size1.size2 {
    font-size: 1.2em;
  }
  .sizing.reset-size1.size3 {
    font-size: 1.4em;
  }
  .fontsize-ensurer.reset-size1.size3 {
    font-size: 1.4em;
  }
  .sizing.reset-size1.size4 {
    font-size: 1.6em;
  }
  .fontsize-ensurer.reset-size1.size4 {
    font-size: 1.6em;
  }
  .sizing.reset-size1.size5 {
    font-size: 1.8em;
  }
  .fontsize-ensurer.reset-size1.size5 {
    font-size: 1.8em;
  }
  .sizing.reset-size1.size6 {
    font-size: 2em;
  }
  .fontsize-ensurer.reset-size1.size6 {
    font-size: 2em;
  }
  .sizing.reset-size1.size7 {
    font-size: 2.4em;
  }
  .fontsize-ensurer.reset-size1.size7 {
    font-size: 2.4em;
  }
  .sizing.reset-size1.size8 {
    font-size: 2.88em;
  }
  .fontsize-ensurer.reset-size1.size8 {
    font-size: 2.88em;
  }
  .sizing.reset-size1.size9 {
    font-size: 3.456em;
  }
  .fontsize-ensurer.reset-size1.size9 {
    font-size: 3.456em;
  }
  .sizing.reset-size1.size10 {
    font-size: 4.148em;
  }
  .fontsize-ensurer.reset-size1.size10 {
    font-size: 4.148em;
  }
  .sizing.reset-size1.size11 {
    font-size: 4.976em;
  }
  .fontsize-ensurer.reset-size1.size11 {
    font-size: 4.976em;
  }
  .sizing.reset-size2.size1 {
    font-size: 0.8333333333em;
  }
  .fontsize-ensurer.reset-size2.size1 {
    font-size: 0.8333333333em;
  }
  .sizing.reset-size2.size2 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size2.size2 {
    font-size: 1em;
  }
  .sizing.reset-size2.size3 {
    font-size: 1.1666666667em;
  }
  .fontsize-ensurer.reset-size2.size3 {
    font-size: 1.1666666667em;
  }
  .sizing.reset-size2.size4 {
    font-size: 1.3333333333em;
  }
  .fontsize-ensurer.reset-size2.size4 {
    font-size: 1.3333333333em;
  }
  .sizing.reset-size2.size5 {
    font-size: 1.5em;
  }
  .fontsize-ensurer.reset-size2.size5 {
    font-size: 1.5em;
  }
  .sizing.reset-size2.size6 {
    font-size: 1.6666666667em;
  }
  .fontsize-ensurer.reset-size2.size6 {
    font-size: 1.6666666667em;
  }
  .sizing.reset-size2.size7 {
    font-size: 2em;
  }
  .fontsize-ensurer.reset-size2.size7 {
    font-size: 2em;
  }
  .sizing.reset-size2.size8 {
    font-size: 2.4em;
  }
  .fontsize-ensurer.reset-size2.size8 {
    font-size: 2.4em;
  }
  .sizing.reset-size2.size9 {
    font-size: 2.88em;
  }
  .fontsize-ensurer.reset-size2.size9 {
    font-size: 2.88em;
  }
  .sizing.reset-size2.size10 {
    font-size: 3.4566666667em;
  }
  .fontsize-ensurer.reset-size2.size10 {
    font-size: 3.4566666667em;
  }
  .sizing.reset-size2.size11 {
    font-size: 4.1466666667em;
  }
  .fontsize-ensurer.reset-size2.size11 {
    font-size: 4.1466666667em;
  }
  .sizing.reset-size3.size1 {
    font-size: 0.7142857143em;
  }
  .fontsize-ensurer.reset-size3.size1 {
    font-size: 0.7142857143em;
  }
  .sizing.reset-size3.size2 {
    font-size: 0.8571428571em;
  }
  .fontsize-ensurer.reset-size3.size2 {
    font-size: 0.8571428571em;
  }
  .sizing.reset-size3.size3 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size3.size3 {
    font-size: 1em;
  }
  .sizing.reset-size3.size4 {
    font-size: 1.1428571429em;
  }
  .fontsize-ensurer.reset-size3.size4 {
    font-size: 1.1428571429em;
  }
  .sizing.reset-size3.size5 {
    font-size: 1.2857142857em;
  }
  .fontsize-ensurer.reset-size3.size5 {
    font-size: 1.2857142857em;
  }
  .sizing.reset-size3.size6 {
    font-size: 1.4285714286em;
  }
  .fontsize-ensurer.reset-size3.size6 {
    font-size: 1.4285714286em;
  }
  .sizing.reset-size3.size7 {
    font-size: 1.7142857143em;
  }
  .fontsize-ensurer.reset-size3.size7 {
    font-size: 1.7142857143em;
  }
  .sizing.reset-size3.size8 {
    font-size: 2.0571428571em;
  }
  .fontsize-ensurer.reset-size3.size8 {
    font-size: 2.0571428571em;
  }
  .sizing.reset-size3.size9 {
    font-size: 2.4685714286em;
  }
  .fontsize-ensurer.reset-size3.size9 {
    font-size: 2.4685714286em;
  }
  .sizing.reset-size3.size10 {
    font-size: 2.9628571429em;
  }
  .fontsize-ensurer.reset-size3.size10 {
    font-size: 2.9628571429em;
  }
  .sizing.reset-size3.size11 {
    font-size: 3.5542857143em;
  }
  .fontsize-ensurer.reset-size3.size11 {
    font-size: 3.5542857143em;
  }
  .sizing.reset-size4.size1 {
    font-size: 0.625em;
  }
  .fontsize-ensurer.reset-size4.size1 {
    font-size: 0.625em;
  }
  .sizing.reset-size4.size2 {
    font-size: 0.75em;
  }
  .fontsize-ensurer.reset-size4.size2 {
    font-size: 0.75em;
  }
  .sizing.reset-size4.size3 {
    font-size: 0.875em;
  }
  .fontsize-ensurer.reset-size4.size3 {
    font-size: 0.875em;
  }
  .sizing.reset-size4.size4 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size4.size4 {
    font-size: 1em;
  }
  .sizing.reset-size4.size5 {
    font-size: 1.125em;
  }
  .fontsize-ensurer.reset-size4.size5 {
    font-size: 1.125em;
  }
  .sizing.reset-size4.size6 {
    font-size: 1.25em;
  }
  .fontsize-ensurer.reset-size4.size6 {
    font-size: 1.25em;
  }
  .sizing.reset-size4.size7 {
    font-size: 1.5em;
  }
  .fontsize-ensurer.reset-size4.size7 {
    font-size: 1.5em;
  }
  .sizing.reset-size4.size8 {
    font-size: 1.8em;
  }
  .fontsize-ensurer.reset-size4.size8 {
    font-size: 1.8em;
  }
  .sizing.reset-size4.size9 {
    font-size: 2.16em;
  }
  .fontsize-ensurer.reset-size4.size9 {
    font-size: 2.16em;
  }
  .sizing.reset-size4.size10 {
    font-size: 2.5925em;
  }
  .fontsize-ensurer.reset-size4.size10 {
    font-size: 2.5925em;
  }
  .sizing.reset-size4.size11 {
    font-size: 3.11em;
  }
  .fontsize-ensurer.reset-size4.size11 {
    font-size: 3.11em;
  }
  .sizing.reset-size5.size1 {
    font-size: 0.5555555556em;
  }
  .fontsize-ensurer.reset-size5.size1 {
    font-size: 0.5555555556em;
  }
  .sizing.reset-size5.size2 {
    font-size: 0.6666666667em;
  }
  .fontsize-ensurer.reset-size5.size2 {
    font-size: 0.6666666667em;
  }
  .sizing.reset-size5.size3 {
    font-size: 0.7777777778em;
  }
  .fontsize-ensurer.reset-size5.size3 {
    font-size: 0.7777777778em;
  }
  .sizing.reset-size5.size4 {
    font-size: 0.8888888889em;
  }
  .fontsize-ensurer.reset-size5.size4 {
    font-size: 0.8888888889em;
  }
  .sizing.reset-size5.size5 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size5.size5 {
    font-size: 1em;
  }
  .sizing.reset-size5.size6 {
    font-size: 1.1111111111em;
  }
  .fontsize-ensurer.reset-size5.size6 {
    font-size: 1.1111111111em;
  }
  .sizing.reset-size5.size7 {
    font-size: 1.3333333333em;
  }
  .fontsize-ensurer.reset-size5.size7 {
    font-size: 1.3333333333em;
  }
  .sizing.reset-size5.size8 {
    font-size: 1.6em;
  }
  .fontsize-ensurer.reset-size5.size8 {
    font-size: 1.6em;
  }
  .sizing.reset-size5.size9 {
    font-size: 1.92em;
  }
  .fontsize-ensurer.reset-size5.size9 {
    font-size: 1.92em;
  }
  .sizing.reset-size5.size10 {
    font-size: 2.3044444444em;
  }
  .fontsize-ensurer.reset-size5.size10 {
    font-size: 2.3044444444em;
  }
  .sizing.reset-size5.size11 {
    font-size: 2.7644444444em;
  }
  .fontsize-ensurer.reset-size5.size11 {
    font-size: 2.7644444444em;
  }
  .sizing.reset-size6.size1 {
    font-size: 0.5em;
  }
  .fontsize-ensurer.reset-size6.size1 {
    font-size: 0.5em;
  }
  .sizing.reset-size6.size2 {
    font-size: 0.6em;
  }
  .fontsize-ensurer.reset-size6.size2 {
    font-size: 0.6em;
  }
  .sizing.reset-size6.size3 {
    font-size: 0.7em;
  }
  .fontsize-ensurer.reset-size6.size3 {
    font-size: 0.7em;
  }
  .sizing.reset-size6.size4 {
    font-size: 0.8em;
  }
  .fontsize-ensurer.reset-size6.size4 {
    font-size: 0.8em;
  }
  .sizing.reset-size6.size5 {
    font-size: 0.9em;
  }
  .fontsize-ensurer.reset-size6.size5 {
    font-size: 0.9em;
  }
  .sizing.reset-size6.size6 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size6.size6 {
    font-size: 1em;
  }
  .sizing.reset-size6.size7 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size6.size7 {
    font-size: 1.2em;
  }
  .sizing.reset-size6.size8 {
    font-size: 1.44em;
  }
  .fontsize-ensurer.reset-size6.size8 {
    font-size: 1.44em;
  }
  .sizing.reset-size6.size9 {
    font-size: 1.728em;
  }
  .fontsize-ensurer.reset-size6.size9 {
    font-size: 1.728em;
  }
  .sizing.reset-size6.size10 {
    font-size: 2.074em;
  }
  .fontsize-ensurer.reset-size6.size10 {
    font-size: 2.074em;
  }
  .sizing.reset-size6.size11 {
    font-size: 2.488em;
  }
  .fontsize-ensurer.reset-size6.size11 {
    font-size: 2.488em;
  }
  .sizing.reset-size7.size1 {
    font-size: 0.4166666667em;
  }
  .fontsize-ensurer.reset-size7.size1 {
    font-size: 0.4166666667em;
  }
  .sizing.reset-size7.size2 {
    font-size: 0.5em;
  }
  .fontsize-ensurer.reset-size7.size2 {
    font-size: 0.5em;
  }
  .sizing.reset-size7.size3 {
    font-size: 0.5833333333em;
  }
  .fontsize-ensurer.reset-size7.size3 {
    font-size: 0.5833333333em;
  }
  .sizing.reset-size7.size4 {
    font-size: 0.6666666667em;
  }
  .fontsize-ensurer.reset-size7.size4 {
    font-size: 0.6666666667em;
  }
  .sizing.reset-size7.size5 {
    font-size: 0.75em;
  }
  .fontsize-ensurer.reset-size7.size5 {
    font-size: 0.75em;
  }
  .sizing.reset-size7.size6 {
    font-size: 0.8333333333em;
  }
  .fontsize-ensurer.reset-size7.size6 {
    font-size: 0.8333333333em;
  }
  .sizing.reset-size7.size7 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size7.size7 {
    font-size: 1em;
  }
  .sizing.reset-size7.size8 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size7.size8 {
    font-size: 1.2em;
  }
  .sizing.reset-size7.size9 {
    font-size: 1.44em;
  }
  .fontsize-ensurer.reset-size7.size9 {
    font-size: 1.44em;
  }
  .sizing.reset-size7.size10 {
    font-size: 1.7283333333em;
  }
  .fontsize-ensurer.reset-size7.size10 {
    font-size: 1.7283333333em;
  }
  .sizing.reset-size7.size11 {
    font-size: 2.0733333333em;
  }
  .fontsize-ensurer.reset-size7.size11 {
    font-size: 2.0733333333em;
  }
  .sizing.reset-size8.size1 {
    font-size: 0.3472222222em;
  }
  .fontsize-ensurer.reset-size8.size1 {
    font-size: 0.3472222222em;
  }
  .sizing.reset-size8.size2 {
    font-size: 0.4166666667em;
  }
  .fontsize-ensurer.reset-size8.size2 {
    font-size: 0.4166666667em;
  }
  .sizing.reset-size8.size3 {
    font-size: 0.4861111111em;
  }
  .fontsize-ensurer.reset-size8.size3 {
    font-size: 0.4861111111em;
  }
  .sizing.reset-size8.size4 {
    font-size: 0.5555555556em;
  }
  .fontsize-ensurer.reset-size8.size4 {
    font-size: 0.5555555556em;
  }
  .sizing.reset-size8.size5 {
    font-size: 0.625em;
  }
  .fontsize-ensurer.reset-size8.size5 {
    font-size: 0.625em;
  }
  .sizing.reset-size8.size6 {
    font-size: 0.6944444444em;
  }
  .fontsize-ensurer.reset-size8.size6 {
    font-size: 0.6944444444em;
  }
  .sizing.reset-size8.size7 {
    font-size: 0.8333333333em;
  }
  .fontsize-ensurer.reset-size8.size7 {
    font-size: 0.8333333333em;
  }
  .sizing.reset-size8.size8 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size8.size8 {
    font-size: 1em;
  }
  .sizing.reset-size8.size9 {
    font-size: 1.2em;
  }
  .fontsize-ensurer.reset-size8.size9 {
    font-size: 1.2em;
  }
  .sizing.reset-size8.size10 {
    font-size: 1.4402777778em;
  }
  .fontsize-ensurer.reset-size8.size10 {
    font-size: 1.4402777778em;
  }
  .sizing.reset-size8.size11 {
    font-size: 1.7277777778em;
  }
  .fontsize-ensurer.reset-size8.size11 {
    font-size: 1.7277777778em;
  }
  .sizing.reset-size9.size1 {
    font-size: 0.2893518519em;
  }
  .fontsize-ensurer.reset-size9.size1 {
    font-size: 0.2893518519em;
  }
  .sizing.reset-size9.size2 {
    font-size: 0.3472222222em;
  }
  .fontsize-ensurer.reset-size9.size2 {
    font-size: 0.3472222222em;
  }
  .sizing.reset-size9.size3 {
    font-size: 0.4050925926em;
  }
  .fontsize-ensurer.reset-size9.size3 {
    font-size: 0.4050925926em;
  }
  .sizing.reset-size9.size4 {
    font-size: 0.462962963em;
  }
  .fontsize-ensurer.reset-size9.size4 {
    font-size: 0.462962963em;
  }
  .sizing.reset-size9.size5 {
    font-size: 0.5208333333em;
  }
  .fontsize-ensurer.reset-size9.size5 {
    font-size: 0.5208333333em;
  }
  .sizing.reset-size9.size6 {
    font-size: 0.5787037037em;
  }
  .fontsize-ensurer.reset-size9.size6 {
    font-size: 0.5787037037em;
  }
  .sizing.reset-size9.size7 {
    font-size: 0.6944444444em;
  }
  .fontsize-ensurer.reset-size9.size7 {
    font-size: 0.6944444444em;
  }
  .sizing.reset-size9.size8 {
    font-size: 0.8333333333em;
  }
  .fontsize-ensurer.reset-size9.size8 {
    font-size: 0.8333333333em;
  }
  .sizing.reset-size9.size9 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size9.size9 {
    font-size: 1em;
  }
  .sizing.reset-size9.size10 {
    font-size: 1.2002314815em;
  }
  .fontsize-ensurer.reset-size9.size10 {
    font-size: 1.2002314815em;
  }
  .sizing.reset-size9.size11 {
    font-size: 1.4398148148em;
  }
  .fontsize-ensurer.reset-size9.size11 {
    font-size: 1.4398148148em;
  }
  .sizing.reset-size10.size1 {
    font-size: 0.2410800386em;
  }
  .fontsize-ensurer.reset-size10.size1 {
    font-size: 0.2410800386em;
  }
  .sizing.reset-size10.size2 {
    font-size: 0.2892960463em;
  }
  .fontsize-ensurer.reset-size10.size2 {
    font-size: 0.2892960463em;
  }
  .sizing.reset-size10.size3 {
    font-size: 0.337512054em;
  }
  .fontsize-ensurer.reset-size10.size3 {
    font-size: 0.337512054em;
  }
  .sizing.reset-size10.size4 {
    font-size: 0.3857280617em;
  }
  .fontsize-ensurer.reset-size10.size4 {
    font-size: 0.3857280617em;
  }
  .sizing.reset-size10.size5 {
    font-size: 0.4339440694em;
  }
  .fontsize-ensurer.reset-size10.size5 {
    font-size: 0.4339440694em;
  }
  .sizing.reset-size10.size6 {
    font-size: 0.4821600771em;
  }
  .fontsize-ensurer.reset-size10.size6 {
    font-size: 0.4821600771em;
  }
  .sizing.reset-size10.size7 {
    font-size: 0.5785920926em;
  }
  .fontsize-ensurer.reset-size10.size7 {
    font-size: 0.5785920926em;
  }
  .sizing.reset-size10.size8 {
    font-size: 0.6943105111em;
  }
  .fontsize-ensurer.reset-size10.size8 {
    font-size: 0.6943105111em;
  }
  .sizing.reset-size10.size9 {
    font-size: 0.8331726133em;
  }
  .fontsize-ensurer.reset-size10.size9 {
    font-size: 0.8331726133em;
  }
  .sizing.reset-size10.size10 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size10.size10 {
    font-size: 1em;
  }
  .sizing.reset-size10.size11 {
    font-size: 1.1996142719em;
  }
  .fontsize-ensurer.reset-size10.size11 {
    font-size: 1.1996142719em;
  }
  .sizing.reset-size11.size1 {
    font-size: 0.2009646302em;
  }
  .fontsize-ensurer.reset-size11.size1 {
    font-size: 0.2009646302em;
  }
  .sizing.reset-size11.size2 {
    font-size: 0.2411575563em;
  }
  .fontsize-ensurer.reset-size11.size2 {
    font-size: 0.2411575563em;
  }
  .sizing.reset-size11.size3 {
    font-size: 0.2813504823em;
  }
  .fontsize-ensurer.reset-size11.size3 {
    font-size: 0.2813504823em;
  }
  .sizing.reset-size11.size4 {
    font-size: 0.3215434084em;
  }
  .fontsize-ensurer.reset-size11.size4 {
    font-size: 0.3215434084em;
  }
  .sizing.reset-size11.size5 {
    font-size: 0.3617363344em;
  }
  .fontsize-ensurer.reset-size11.size5 {
    font-size: 0.3617363344em;
  }
  .sizing.reset-size11.size6 {
    font-size: 0.4019292605em;
  }
  .fontsize-ensurer.reset-size11.size6 {
    font-size: 0.4019292605em;
  }
  .sizing.reset-size11.size7 {
    font-size: 0.4823151125em;
  }
  .fontsize-ensurer.reset-size11.size7 {
    font-size: 0.4823151125em;
  }
  .sizing.reset-size11.size8 {
    font-size: 0.578778135em;
  }
  .fontsize-ensurer.reset-size11.size8 {
    font-size: 0.578778135em;
  }
  .sizing.reset-size11.size9 {
    font-size: 0.6945337621em;
  }
  .fontsize-ensurer.reset-size11.size9 {
    font-size: 0.6945337621em;
  }
  .sizing.reset-size11.size10 {
    font-size: 0.8336012862em;
  }
  .fontsize-ensurer.reset-size11.size10 {
    font-size: 0.8336012862em;
  }
  .sizing.reset-size11.size11 {
    font-size: 1em;
  }
  .fontsize-ensurer.reset-size11.size11 {
    font-size: 1em;
  }
  .delimsizing.size1 {
    font-family: $font-family_9;
  }
  .delimsizing.size2 {
    font-family: $font-family_10;
  }
  .delimsizing.size3 {
    font-family: $font-family_11;
  }
  .delimsizing.size4 {
    font-family: $font-family_12;
  }
  .delimsizing.mult {
    .delim-size1 {
      >span {
        font-family: $font-family_9;
      }
    }
    .delim-size4 {
      >span {
        font-family: $font-family_12;
      }
    }
  }
  .nulldelimiter {
    display: inline-block;
    width: 0.12em;
  }
  .delimcenter {
    position: relative;
  }
  .op-symbol {
    position: relative;
  }
  .op-symbol.small-op {
    font-family: $font-family_9;
  }
  .op-symbol.large-op {
    font-family: $font-family_10;
  }
  .op-limits {
    >.vlist-t {
      text-align: center;
    }
  }
  .accent {
    >.vlist-t {
      text-align: center;
    }
    .accent-body {
      position: relative;
      &:not(.accent-full) {
        width: 0;
      }
    }
  }
  .overlay {
    display: block;
  }
  .mtable {
    .vertical-separator {
      display: inline-block;
      min-width: 1px;
    }
    .arraycolsep {
      display: inline-block;
    }
    .col-align-c {
      >.vlist-t {
        text-align: center;
      }
    }
    .col-align-l {
      >.vlist-t {
        text-align: left;
      }
    }
    .col-align-r {
      >.vlist-t {
        text-align: right;
      }
    }
  }
  .svg-align {
    text-align: left;
  }
  svg {
    display: block;
    position: absolute;
    width: 100%;
    height: inherit;
    fill: currentColor;
    stroke: currentColor;
    fill-rule: nonzero;
    fill-opacity: 1;
    stroke-width: 1;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 4;
    stroke-dasharray: none;
    stroke-dashoffset: 0;
    stroke-opacity: 1;
    path {
      stroke: none;
    }
  }
  img {
    border-style: none;
    min-width: 0;
    min-height: 0;
    max-width: none;
    max-height: none;
  }
  .stretchy {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    &::before {
      content: "";
    }
    &::after {
      content: "";
    }
  }
  .hide-tail {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .halfarrow-left {
    position: absolute;
    left: 0;
    width: 50.2%;
    overflow: hidden;
  }
  .halfarrow-right {
    position: absolute;
    right: 0;
    width: 50.2%;
    overflow: hidden;
  }
  .brace-left {
    position: absolute;
    left: 0;
    width: 25.1%;
    overflow: hidden;
  }
  .brace-center {
    position: absolute;
    left: 25%;
    width: 50%;
    overflow: hidden;
  }
  .brace-right {
    position: absolute;
    right: 0;
    width: 25.1%;
    overflow: hidden;
  }
  .x-arrow-pad {
    padding: 0 0.5em;
  }
  .cd-arrow-pad {
    padding: 0 0.55556em 0 0.27778em;
  }
  .x-arrow {
    text-align: center;
  }
  .mover {
    text-align: center;
  }
  .munder {
    text-align: center;
  }
  .boxpad {
    padding: 0 0.3em;
  }
  .fbox {
    box-sizing: border-box;
    border: 0.04em solid;
  }
  .fcolorbox {
    box-sizing: border-box;
    border: 0.04em solid;
  }
  .cancel-pad {
    padding: 0 0.2em;
  }
  .cancel-lap {
    margin-left: -0.2em;
    margin-right: -0.2em;
  }
  .sout {
    border-bottom-style: solid;
    border-bottom-width: 0.08em;
  }
  .angl {
    box-sizing: border-box;
    border-top: 0.049em solid;
    border-right: 0.049em solid;
    margin-right: 0.03889em;
  }
  .anglpad {
    padding: 0 0.03889em;
  }
  .eqn-num {
    &::before {
      counter-increment: katexEqnNo;
      content: "(" counter(katexEqnNo) ")";
    }
  }
  .mml-eqn-num {
    &::before {
      counter-increment: mmlEqnNo;
      content: "(" counter(mmlEqnNo) ")";
    }
  }
  .mtr-glue {
    width: 50%;
  }
  .cd-vert-arrow {
    display: inline-block;
    position: relative;
  }
  .cd-label-left {
    display: inline-block;
    position: absolute;
    right: calc(50% + 0.3em);
    text-align: left;
  }
  .cd-label-right {
    display: inline-block;
    position: absolute;
    left: calc(50% + 0.3em);
    text-align: right;
  }
}
.katex-display {
  display: block;
  margin: 1em 0;
  text-align: center;
  >.katex {
    display: block;
    text-align: center;
    white-space: nowrap;
    >.katex-html {
      display: block;
      position: relative;
      >.tag {
        position: absolute;
        right: 0;
      }
    }
  }
}
.katex-display.leqno {
  >.katex {
    >.katex-html {
      >.tag {
        left: 0;
        right: auto;
      }
    }
  }
}
.katex-display.fleqn {
  >.katex {
    text-align: left;
    padding-left: 2em;
  }
}
body {
  counter-reset: katexEqnNo mmlEqnNo;
}

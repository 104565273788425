@import 'layouts/attribution';
@import 'layouts/contacts';
@import 'layouts/events';
@import 'layouts/recommendations';

.home {
  .home-logo {
      display: flex;
      width: 100%;
      justify-content: center;

      img {
        height: 20em;
      }
  }

  article {
    padding-top: 0;
  }
}

p {
  line-height: 1.4;
}

.picture-inline {
  float: right;
  width: 45%;

  img {
    padding: 2% 2%;
  }

  @media screen and (max-width: $sm-break) {
    width: 100%;
  }
}
